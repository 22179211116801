<template>
  <div>
    <v-system-bar
      height="47"
      :color="
        !empty(form) &&
        !empty(form.status) &&
        (form.status == 'Active' ||
          form.status == 'Approved' ||
          form.status == 'Confirmed' ||
          form.status.includes('Approved'))
          ? '#aeddff'
          : !empty(form) && !empty(form.status) && form.status == 'Pending'
          ? '#fc9303'
          : '#ff5252'
      "
    >
      <h3
        :class="
          !empty(form) &&
          !empty(form.status) &&
          (form.status == 'Active' ||
            form.status == 'Approved' ||
            form.status == 'Confirmed' ||
            form.status.includes('Approved'))
            ? 'black--text'
            : 'white--text'
        "
      >
        {{ title.toUpperCase() }}
      </h3>
      <span
        class="ml-3 mt-0 white--text blink"
        v-if="
          !empty(form) &&
          !empty(form.status) &&
          (form.status == 'Disabled' ||
            form.status == 'Rejected' ||
            form.status == 'Cancelled')
        "
      >
        - RECORD IS DISABLED
      </span>
      <v-spacer></v-spacer>
      <template>
        <el-input
          v-if="showSearch"
          v-model="search"
          name="search"
          ref="search"
          size="mini"
          style="max-width: 300px"
          :placeholder="searchHint"
          class="mr-1"
          @keyup.enter.native="searchAction"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            size="mini"
            @click="searchAction"
          ></el-button>
        </el-input>
        <v-btn
          title
          :color="
            !empty(form) &&
            !empty(form.status) &&
            (form.status == 'Disabled' ||
              form.status == 'Rejected' ||
              form.status == 'Cancelled')
              ? 'primary'
              : 'error'
          "
          v-if="view == 'view' && showCloseButton"
          class="mr-2"
          @click="close"
          elevation="0"
        >
          <v-icon color="white">mdi-close</v-icon>
          {{ closeButtonText }}
        </v-btn>
        <v-btn
          title
          :color="customButtonColor"
          v-if="showCustomButton"
          @click="customButton"
          class="mr-2 hidden-sm-and-down"
          elevation="0"
        >
          <v-icon color="white">mdi-save</v-icon>
          {{ customButtonText }}
        </v-btn>
        <v-btn
          title
          :color="customButtonColor1"
          v-if="showCustomButton1"
          @click="customButton1"
          class="mr-2 hidden-sm-and-down"
          elevation="0"
        >
          <v-icon color="white">mdi-save</v-icon>
          {{ customButtonText1 }}
        </v-btn>
        <v-btn
          title
          :color="customButtonColor2"
          v-if="showCustomButton2"
          @click="customButton2"
          class="mr-2 hidden-sm-and-down"
          elevation="0"
        >
          <v-icon color="white">mdi-save</v-icon>
          {{ customButtonText2 }}
        </v-btn>
        <v-btn
          title
          :color="customButtonColor3"
          v-if="showCustomButton3"
          @click="customButton3"
          class="mr-2 hidden-sm-and-down"
          elevation="0"
        >
          <v-icon color="white">mdi-save</v-icon>
          {{ customButtonText3 }}
        </v-btn>
        <v-btn
          title
          color="warning"
          v-if="allowNew && view == 'view' && !noNew && showNewButton"
          @click="newForm"
          class="mr-2 hidden-sm-and-down"
          elevation="0"
        >
          <v-icon color="white">mdi-plus</v-icon>
          {{ newButtonText }}
        </v-btn>
        <template
          v-if="
            (!empty(form) && empty(form.id)) ||
            (!empty(form) && form.id > 0 && !readonly)
          "
        >
          <v-btn
            title
            color="primary"
            v-if="
              allowEdit &&
              !empty(form.id) &&
              view == 'view' &&
              showEditButton &&
              form.status != 'Disabled' &&
              showEditButton
            "
            @click="handleEdit"
            class="hidden-sm-and-down"
            elevation="0"
          >
            <v-icon color="white">mdi-pencil</v-icon>
            {{ editButtonText }}
          </v-btn>
          <v-btn
            title
            color="warning"
            v-if="view != 'view' && showCancelButton"
            @click="cancelForm"
            class="hidden-sm-and-down"
            elevation="0"
          >
            <v-icon color="white">mdi-cancel</v-icon>
            {{ cancelButtonText }}
          </v-btn>
          <v-btn
            title
            color="success"
            class="ml-2 hidden-sm-and-down"
            v-if="
              (allowEdit || allowNew) &&
              (view == 'new' || view == 'edit') &&
              showSaveButton
            "
            @click="saveForm"
            elevation="0"
          >
            <v-icon color="white">mdi-content-save</v-icon>
            {{ saveButtonText }}
          </v-btn>
          <v-btn
            title
            color="error"
            class="ml-2 hidden-sm-and-down"
            v-if="
              allowDisable &&
              !empty(form.id) &&
              form.status == 'Active' &&
              view == 'view' &&
              !noNew &&
              showDisableButton
            "
            @click="disableRecord(false)"
            elevation="0"
          >
            <v-icon color="white">mdi-close-circle</v-icon>
            {{ disableButtonText }}
          </v-btn>
          <v-btn
            title
            color="success"
            class="ml-2 hidden-sm-and-down"
            v-if="
              allowDisable &&
              !empty(form.id) &&
              form.status == 'Disabled' &&
              view == 'view' &&
              showEnableButton
            "
            @click="disableRecord(true)"
            elevation="0"
          >
            <v-icon color="white">mdi-close-circle</v-icon>
            {{ enableButtonText }}
          </v-btn>
        </template>
      </template>
    </v-system-bar>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  created() {},
  watch: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    form: {
      status: null,
      id: null,
    },
    permissions: null,
    view: {
      type: String,
      default: "",
    },
    noNew: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showCustomButton: {
      type: Boolean,
      default: false,
    },
    customButtonText: {
      type: String,
      default: "",
    },
    customButtonColor: {
      type: String,
      default: "",
    },
    showCustomButton1: {
      type: Boolean,
      default: false,
    },
    customButtonText1: {
      type: String,
      default: "",
    },
    customButtonColor1: {
      type: String,
      default: "",
    },
    showCustomButton2: {
      type: Boolean,
      default: false,
    },
    customButtonText2: {
      type: String,
      default: "",
    },
    customButtonColor2: {
      type: String,
      default: "",
    },
    showCustomButton3: {
      type: Boolean,
      default: false,
    },
    customButtonText3: {
      type: String,
      default: "",
    },
    customButtonColor3: {
      type: String,
      default: "",
    },
    disableButtonText: {
      type: String,
      default: "Disable",
    },
    enableButtonText: {
      type: String,
      default: "Enable",
    },
    disableConfirmText: {
      type: String,
      default: "Once disabled users wont be able to view the records",
    },
    disableConfirmTitle: {
      type: String,
      default: "Do you want to disabled the record?",
    },
    disableConfirmButtonText: {
      type: String,
      default: "Yes, disable it!",
    },
    enableConfirmText: {
      type: String,
      default: "Once enabled users can view the record",
    },
    enableConfirmTitle: {
      type: String,
      default: "Do you want Enable the record?",
    },
    enableConfirmButtonText: {
      type: String,
      default: "Yes, enable it!",
    },
    saveButtonText: {
      type: String,
      default: "Save",
    },
    newButtonText: {
      type: String,
      default: "New",
    },
    closeButtonText: {
      type: String,
      default: "Close",
    },
    editButtonText: {
      type: String,
      default: "Edit",
    },
    cancelButtonText: {
      type: String,
      default: "Cancel",
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showNewButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    showEnableButton: {
      type: Boolean,
      default: true,
    },
    showDisableButton: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    searchHint: {
      type: String,
      default: "Search",
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      search: null,
    };
  },
  methods: {
    saveForm() {
      this.$emit("saveForm");
    },
    disableRecord(enable) {
      Swal.fire({
        title: enable ? this.enableConfirmTitle : this.disableConfirmTitle,
        text: enable ? this.enableConfirmText : this.disableConfirmText,
        type: "question",
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonColor: "#5daf34",
        cancelButtonColor: "#d33",
        showCancelButton: true,
        confirmButtonText: enable
          ? this.enableConfirmButtonText
          : this.disableConfirmButtonText,
      }).then((result) => {
        if (result.value) {
          this.$emit("disableRecord", enable);
        }
      });
    },
    searchAction() {
      this.$emit("search", this.search);
    },
    newForm() {
      this.$emit("newForm");
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.$emit("cancelForm");
    },
    handleEdit() {
      this.$emit("edit");
    },
    customButton() {
      this.$emit("customButton");
    },
    customButton1() {
      this.$emit("customButton1");
    },
    customButton2() {
      this.$emit("customButton2");
    },
    customButton3() {
      this.$emit("customButton3");
    },
  },
  computed: {
    allowNew() {
      return true;
    },
    allowEdit() {
      return true;
    },
    allowDisable() {
      return true;
    },
    allowEnable() {
      return true;
    },
  },
  name: "FormHeader",
};
</script>
