<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <el-button
          type="success"
          icon="el-icon-folder-opened"
          class="mr-2"
          circle
          v-bind="attrs"
          v-if="reports.length > 0"
          v-on="on"
        ></el-button>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in reports" :key="index">
          <v-list-item-title style="cursor: hand" @click="showReport(item)">
            {{ item.report_name }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <view-report
      :data="selectedReport"
      :show="showViewReport"
      @cancel="showViewReport = false"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { Button } from "element-ui";
Vue.use(Button);
import ViewReport from "./ViewReport.vue";
export default {
  created() {},
  watch: {},
  props: {
    reports: null,
  },
  components: {
    ViewReport,
  },
  data() {
    return {
      selectedReport: null,
      showViewReport: false,
    };
  },
  methods: {
    showReport(report) {
      this.selectedReport = report;
      this.showViewReport = true;
    },
  },
  computed: {},
  name: "ReportMenu",
};
</script>
