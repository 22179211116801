<template>
  <v-dialog
    v-model="showForm"
    width="600px"
    hide-overlay
    :fullscreen="tableData.length > 0"
    persistent
    transition="dialog-bottom-transition"
  >
    <div class="pa-5">
      <v-card class="pb-10">
        <v-system-bar height="47" color="#aeddff">
          <v-icon @click="cancel">mdi-close</v-icon>
          <h3 class="black--text">
            {{ toTitleCase(title) }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            title
            color="default"
            v-if="tableData.length > 0"
            @click="exportToExcel"
            elevation="0"
            class="float-right mr-2"
          >
            <v-icon color="white">mdi-microsoft-excel</v-icon>
            Export to Excel
          </v-btn>
          <v-btn
            title
            color="warning"
            @click="showFilterRowScreen = true"
            elevation="0"
            class="float-right mr-2"
          >
            <v-icon color="white">mdi-filter</v-icon>
            Show Filter Options
          </v-btn>
          <v-btn
            title
            color="success"
            @click="generateReport"
            elevation="0"
            class="float-right mr-2"
          >
            <v-icon color="white">mdi-finance</v-icon>
            Generate Report
          </v-btn>
          <v-btn title color="warning" @click="cancel" elevation="0">
            <v-icon color="white">mdi-cancel</v-icon>
            Close
          </v-btn>
        </v-system-bar>
        <v-divider />
        <v-card-text>
          <div class="row">
            <div class="col-lg-12 col-sm-12">
              <el-table
                :data="pageData"
                style="width: 100%"
                :summary-method="getSummaries"
                height="500"
                show-summary
              >
                <el-table-column
                  :prop="f.field_alias"
                  :key="f.field_name"
                  :label="f.field_alias"
                  :right="isSumColumn(f.field_type)"
                  v-for="f in queryFields"
                >
                  <template slot="header" slot-scope="scope">
                    <el-input
                      size="mini"
                      :placeholder="scope.row"
                      :disabled="true"
                      v-model="f.field_alias"
                    />
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="sizeChanged"
                @current-change="getData"
                :current-page.sync="currentPage"
                :page-sizes="[10, 25, 50, 100, 200, 300, 400]"
                :page-size="perPage"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="mb-3"
              ></el-pagination>
            </div>
          </div>
          <v-divider class="mt-5 mb-5" />
          <v-btn
            title
            color="warning"
            @click="showFilterRowScreen = true"
            elevation="0"
            class="float-right mr-2"
          >
            <v-icon color="white">mdi-filter</v-icon>
            Show Filter Options
          </v-btn>
          <v-btn
            title
            color="success"
            @click="generateReport"
            elevation="0"
            class="float-right mr-2"
          >
            <v-icon color="white">mdi-finance</v-icon>
            Generate Report
          </v-btn>
          <v-btn title color="warning" @click="cancel" elevation="0">
            <v-icon color="white">mdi-cancel</v-icon>
            Close
          </v-btn>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog
      v-model="showFilterRowScreen"
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <div class="pa-2">
        <v-card class="pb-10">
          <v-system-bar height="47" color="#aeddff">
            <h3 class="black--text">Filter Report Data</h3>
            <v-spacer></v-spacer>
            <v-btn
              title
              color="warning"
              @click="showFilterRowScreen = false"
              elevation="0"
            >
              <v-icon color="white">mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <v-btn
              title
              color="success"
              class="ml-2"
              @click="generateReport"
              elevation="0"
            >
              <v-icon color="white">mdi-content-save</v-icon>
              Generate Report
            </v-btn>
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <el-table :data="filterScreenRows" style="width: 100%">
                  <el-table-column prop="field_display_name" label="Field">
                    <template slot-scope="scope">
                      {{
                        empty(scope.row.field_display_name)
                          ? toTitleCase(scope.row.field_name)
                          : toTitleCase(scope.row.field_display_name)
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="value" label="Value">
                    <template slot-scope="scope">
                      <el-input
                        size="mini"
                        v-if="scope.row.filter_type == 'list'"
                        :readonly="true"
                        :value="
                          !empty(scope.row.value)
                            ? scope.row.value[scope.row.list_display_field]
                            : ''
                        "
                      >
                        <span v-if="!empty(scope.row.value)">
                          {{ scope.row.value[scope.row.list_display_field] }}
                        </span>
                        <el-button
                          slot="append"
                          size="mini"
                          icon="mdi mdi-filter-variant"
                          @click="showListView(scope.row, scope.$index)"
                        ></el-button>
                      </el-input>
                      <el-input-number
                        size="mini"
                        v-else-if="scope.row.filter_type == 'number'"
                        v-model="scope.row.value"
                        :controls="false"
                      ></el-input-number>
                      <el-date-picker
                        size="mini"
                        style="width: 100%; max-width: 200px"
                        v-else-if="scope.row.filter_type == 'date'"
                        value-format="yyyy-MM-dd"
                        :picker-options="datePickerOptions"
                        placeholder="Date"
                        class="mr-1"
                        v-model="scope.row.value"
                      ></el-date-picker>
                      <el-date-picker
                        size="mini"
                        style="width: 100%; max-width: 200px"
                        v-else-if="scope.row.filter_type == 'datetime'"
                        value-format="yyyy-MM-dd HH:mm"
                        :picker-options="datePickerOptions"
                        type="datetime"
                        placeholder="Date Time"
                        class="mr-1"
                        v-model="scope.row.value"
                      ></el-date-picker>
                      <el-input
                        size="mini"
                        v-else
                        v-model="scope.row.value"
                      ></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog
      v-model="showFilterListScreen"
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <div class="pa-2">
        <v-card class="pb-10">
          <v-system-bar height="47" color="#aeddff">
            <h3 class="black--text">Select from List</h3>
            <v-spacer></v-spacer>
            <v-btn
              title
              color="warning"
              @click="showFilterListScreen = false"
              elevation="0"
            >
              <v-icon color="white">mdi-cancel</v-icon>
              Cancel
            </v-btn>
            <!--<v-btn
                title
                color="success"
                class="ml-2"
                @click="selectFilterListValueItem"
                elevation="0"
              >
                <v-icon color="white">mdi-content-save</v-icon>
                SELECT LIST ITEM
              </v-btn>-->
          </v-system-bar>
          <v-divider />
          <v-card-text>
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <el-table
                  :data="filterItemList"
                  style="width: 100%"
                  @cell-dblclick="selectListValue"
                >
                  <el-table-column prop="list_item_name" label="List Item">
                    <template slot-scope="scope">
                      {{ scope.row[selectedFilterRowField.list_display_field] }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-dialog>
  </v-dialog>
</template>
<script>
import Vue from "vue";
import { Button, Form, FormItem, Input } from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
import { saveAs } from "file-saver";
import XLSX from "xlsx";
export default {
  created() {},
  watch: {
    show: function () {
      this.showForm = this.show;
      if (this.show) {
        this.getReport();
      }
    },
  },
  props: {
    data: null,
    show: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {},
  data() {
    return {
      permissions: [],
      reportData: null,
      tableData: [],
      pageData: [],
      loading: false,
      showForm: false,
      title: null,
      filterScreenRows: [],
      queryTables: [],
      queryFields: [],
      queryJoins: [],
      sumFields: [],
      queryFilterFields: [],
      showfilterFieldRows: false,
      showFilterRowScreen: false,
      showFilterListScreen: false,
      filterItemList: [],
      end_point: "system/general/reports/",
      currentPage: 1,
      perPage: 10,
      total: 0,
    };
  },

  methods: {
    getReport() {
      if (this.empty(this.data)) return;
      this.title = this.data.report_name;
      this.loading = true;
      this.api("system/general/reports/record", this.data)
        .then((response) => {
          this.loading = false;
          this.reportData = response.data;
          this.prepareQueryFields();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    prepareQueryFields() {
      if (this.empty(this.reportData.report_query)) return;
      let q = JSON.parse(this.reportData.report_query);
      console.log(q);
      this.queryTables = this.empty(q.queryTables) ? [] : q.queryTables;
      this.queryFields = this.empty(q.queryFields) ? [] : q.queryFields;
      this.queryJoins = this.empty(q.queryJoins) ? [] : q.queryJoins;
      this.sumFields = this.empty(q.sumFields) ? [] : q.sumFields;
      this.queryFilterFields = this.empty(q.queryFilterFields)
        ? []
        : q.queryFilterFields;
      if (this.queryFilterFields.length > 0) {
        this.prepareFilterFields();
      }
    },
    prepareFilterFields() {
      console.log("prepareFilterFields");
      this.filterScreenRows = [];
      this.queryFilterFields.forEach((field) => {
        if (field.filter_type != "hidden-fixed-filter") {
          this.filterScreenRows.push(field);
        }
      });
      /*this.filterScreenRows.forEach((field, index) => {
        if (field.filter_type == "list") {
          this.getListValues(field, index);
        }
      });*/
      var pending = false;
      if (this.filterScreenRows.length > 0) {
        this.filterScreenRows.forEach((field, index) => {
          if (this.empty(field.value) && field.compulsory == true) {
            pending = true;
          }
          if (this.filterScreenRows.length == index + 1) {
            if (pending == true && this.showFilterRowScreen == true) {
              this.showInfo("Select values for all the Compulsory Fields");
            }
            this.showFilterRowScreen = true;
            if (!pending) {
              this.getDataForReport();
            }
          }
        });
      }
    },
    generateReport() {
      console.log("generateReport");
      var pending = false;
      //var count = 0;
      console.log(this.filterScreenRows);
      if (this.filterScreenRows.length > 0) {
        this.filterScreenRows.forEach((field, index) => {
          if (this.empty(field.value) && field.compulsory == true) {
            pending = true;
          }
          console.log(pending);
          console.log(this.showFilterRowScreen);
          if (this.filterScreenRows.length == index + 1) {
            console.log(this.showfilterFieldRows);
            if (pending == true && this.showFilterRowScreen == true) {
              this.showInfo("Select values for all the Compulsory Fields");
            }
            this.showFilterRowScreen = true;
            if (!pending) {
              this.getDataForReport();
            }
          }
        });
      }
    },
    getDataForReport() {
      this.loading = true;
      this.showFilterRowScreen = false;
      let f = {
        //sql: this.data.sql,
        id: this.data.id,
        filter_fields: this.filterScreenRows,
      };
      this.api(this.end_point + "view-report-data", f)
        .then((response) => {
          this.loading = false;
          this.tableData = response.data;
          this.pageData = this.tableData.splice(
            (this.currentPage - 1) * this.perPage,
            this.perPage
          );
          this.total = this.tableData.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectListValue(row) {
      if (this.empty(row)) {
        return;
      }
      this.filterScreenRows[this.filterScreenRowIndex].value = row;
      this.showFilterListScreen = false;
    },
    getListValues() {
      this.loading = true;
      this.filterItemList = [];
      this.api(this.end_point + "get-list-data", this.selectedFilterRowField)
        .then((response) => {
          this.loading = false;
          //this.filterScreenRows[index].list_values = response.data;
          this.filterItemList = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showListView(row, index) {
      console.log(row);
      console.log(index);
      this.selectedFilterRowField = row;
      this.filterScreenRowIndex = index;
      this.getListValues();
      this.showFilterListScreen = true;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        /*if (index === 0) {
          sums[index] = "Total";
          return;
        }*/
        if (this.empty(column.property)) return;
        let col = this.queryFields.filter(
          (e) => e.field_name == column.property
        );
        let sum =
          this.sumFields.filter((e) => e.field_type == col[0].field_type)
            .length > 0;
        if (sum == false) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    isSumColumn(field_type) {
      return (
        this.sumFieldTypes.filter((e) => e.field_type == field_type).length > 0
      );
    },
    sizeChanged(page) {
      this.perPage = page;
      this.getData();
    },
    fitlerData() {
      this.currentPage = 1;
      this.getData();
    },
    getData() {
      this.pageData = this.tableData.splice(
        (this.currentPage - 1) * this.perPage,
        this.perPage
      );
    },
    exportToExcel() {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      var ws = XLSX.utils.json_to_sheet(this.tableData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      var rightNow = new Date();
      var fileName =
        this.data.report_name +
        rightNow.toISOString().slice(0, 10).replace(/-/g, "");
      saveAs(data, fileName + fileExtension);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  computed: {},
  name: "ViewReport",
};
</script>
<style scoped>
.v-dialog {
  overflow-y: none !important;
}
</style>
