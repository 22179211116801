<template>
  <el-pagination
    @size-change="sizeChanged"
    @current-change="getData"
    :current-page.sync="current_page"
    :page-sizes="[10, 25, 50, 100, 200, 300, 400]"
    :page-size="perPage"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    class="mb-3"
  ></el-pagination>
</template>
<script>
import Vue from "vue";
import { Pagination } from "element-ui";
Vue.use(Pagination);
export default {
  created() {},
  watch: {
    currentPage: function () {
      this.current_page = this.currentPage;
    },
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    permissions: null,
    reports: null,
  },
  newd() {},
  components: {},
  data() {
    return {
      current_page: 1,
    };
  },
  methods: {
    sizeChanged(page) {
      this.$emit("sizeChanged", page);
    },
    getData() {
      this.$emit("pageChanged", this.current_page);
    },
  },
  computed: {},
  name: "IndexFooter",
};
</script>
