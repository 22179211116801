var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-system-bar',{attrs:{"height":"47","color":!_vm.empty(_vm.form) &&
      !_vm.empty(_vm.form.status) &&
      (_vm.form.status == 'Active' ||
        _vm.form.status == 'Approved' ||
        _vm.form.status == 'Confirmed' ||
        _vm.form.status.includes('Approved'))
        ? '#aeddff'
        : !_vm.empty(_vm.form) && !_vm.empty(_vm.form.status) && _vm.form.status == 'Pending'
        ? '#fc9303'
        : '#ff5252'}},[_c('h3',{class:!_vm.empty(_vm.form) &&
        !_vm.empty(_vm.form.status) &&
        (_vm.form.status == 'Active' ||
          _vm.form.status == 'Approved' ||
          _vm.form.status == 'Confirmed' ||
          _vm.form.status.includes('Approved'))
          ? 'black--text'
          : 'white--text'},[_vm._v(" "+_vm._s(_vm.title.toUpperCase())+" ")]),(
        !_vm.empty(_vm.form) &&
        !_vm.empty(_vm.form.status) &&
        (_vm.form.status == 'Disabled' ||
          _vm.form.status == 'Rejected' ||
          _vm.form.status == 'Cancelled')
      )?_c('span',{staticClass:"ml-3 mt-0 white--text blink"},[_vm._v(" - RECORD IS DISABLED ")]):_vm._e(),_c('v-spacer'),[(_vm.showSearch)?_c('el-input',{ref:"search",staticClass:"mr-1",staticStyle:{"max-width":"300px"},attrs:{"name":"search","size":"mini","placeholder":_vm.searchHint},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchAction.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search","size":"mini"},on:{"click":_vm.searchAction},slot:"append"})],1):_vm._e(),(_vm.view == 'view' && _vm.showCloseButton)?_c('v-btn',{staticClass:"mr-2",attrs:{"title":"","color":!_vm.empty(_vm.form) &&
          !_vm.empty(_vm.form.status) &&
          (_vm.form.status == 'Disabled' ||
            _vm.form.status == 'Rejected' ||
            _vm.form.status == 'Cancelled')
            ? 'primary'
            : 'error',"elevation":"0"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(_vm.closeButtonText)+" ")],1):_vm._e(),(_vm.showCustomButton)?_c('v-btn',{staticClass:"mr-2 hidden-sm-and-down",attrs:{"title":"","color":_vm.customButtonColor,"elevation":"0"},on:{"click":_vm.customButton}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-save")]),_vm._v(" "+_vm._s(_vm.customButtonText)+" ")],1):_vm._e(),(_vm.showCustomButton1)?_c('v-btn',{staticClass:"mr-2 hidden-sm-and-down",attrs:{"title":"","color":_vm.customButtonColor1,"elevation":"0"},on:{"click":_vm.customButton1}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-save")]),_vm._v(" "+_vm._s(_vm.customButtonText1)+" ")],1):_vm._e(),(_vm.showCustomButton2)?_c('v-btn',{staticClass:"mr-2 hidden-sm-and-down",attrs:{"title":"","color":_vm.customButtonColor2,"elevation":"0"},on:{"click":_vm.customButton2}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-save")]),_vm._v(" "+_vm._s(_vm.customButtonText2)+" ")],1):_vm._e(),(_vm.showCustomButton3)?_c('v-btn',{staticClass:"mr-2 hidden-sm-and-down",attrs:{"title":"","color":_vm.customButtonColor3,"elevation":"0"},on:{"click":_vm.customButton3}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-save")]),_vm._v(" "+_vm._s(_vm.customButtonText3)+" ")],1):_vm._e(),(_vm.allowNew && _vm.view == 'view' && !_vm.noNew && _vm.showNewButton)?_c('v-btn',{staticClass:"mr-2 hidden-sm-and-down",attrs:{"title":"","color":"warning","elevation":"0"},on:{"click":_vm.newForm}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.newButtonText)+" ")],1):_vm._e(),(
          (!_vm.empty(_vm.form) && _vm.empty(_vm.form.id)) ||
          (!_vm.empty(_vm.form) && _vm.form.id > 0 && !_vm.readonly)
        )?[(
            _vm.allowEdit &&
            !_vm.empty(_vm.form.id) &&
            _vm.view == 'view' &&
            _vm.showEditButton &&
            _vm.form.status != 'Disabled' &&
            _vm.showEditButton
          )?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"title":"","color":"primary","elevation":"0"},on:{"click":_vm.handleEdit}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.editButtonText)+" ")],1):_vm._e(),(_vm.view != 'view' && _vm.showCancelButton)?_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"title":"","color":"warning","elevation":"0"},on:{"click":_vm.cancelForm}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cancel")]),_vm._v(" "+_vm._s(_vm.cancelButtonText)+" ")],1):_vm._e(),(
            (_vm.allowEdit || _vm.allowNew) &&
            (_vm.view == 'new' || _vm.view == 'edit') &&
            _vm.showSaveButton
          )?_c('v-btn',{staticClass:"ml-2 hidden-sm-and-down",attrs:{"title":"","color":"success","elevation":"0"},on:{"click":_vm.saveForm}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.saveButtonText)+" ")],1):_vm._e(),(
            _vm.allowDisable &&
            !_vm.empty(_vm.form.id) &&
            _vm.form.status == 'Active' &&
            _vm.view == 'view' &&
            !_vm.noNew &&
            _vm.showDisableButton
          )?_c('v-btn',{staticClass:"ml-2 hidden-sm-and-down",attrs:{"title":"","color":"error","elevation":"0"},on:{"click":function($event){return _vm.disableRecord(false)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.disableButtonText)+" ")],1):_vm._e(),(
            _vm.allowDisable &&
            !_vm.empty(_vm.form.id) &&
            _vm.form.status == 'Disabled' &&
            _vm.view == 'view' &&
            _vm.showEnableButton
          )?_c('v-btn',{staticClass:"ml-2 hidden-sm-and-down",attrs:{"title":"","color":"success","elevation":"0"},on:{"click":function($event){return _vm.disableRecord(true)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.enableButtonText)+" ")],1):_vm._e()]:_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }