<template>
  <div>
    <v-system-bar height="47" color="primary">
      <h3 class="white--text">{{ title }}</h3>
      <v-spacer></v-spacer>
      <v-text-field
        dense
        v-if="showSearch"
        solo
        height="20px"
        placeholder="Type Search Keyword and hit Enter Key"
        class="mt-6 mr-2 search-input"
        color="white"
        prepend-inner-icon="mdi-magnify"
        @keyup.enter.native="fitlerData"
        v-model="search"
        style="max-width: 300px"
      ></v-text-field>
      <select-list
        v-model="list_value"
        v-if="showList"
        :editor="listEditor"
        :readonly="true"
        :placeholder="listPlaceholder"
        class="mr-1"
        @change="listChanged"
      />
      <report-menu :reports="reports" v-if="allowReports" />
      <v-btn
        title
        color="warning"
        @click="newRecord"
        v-if="allowNew && !noNew"
        elevation="0"
      >
        <v-icon color="white">mdi-plus</v-icon>
        New
      </v-btn>
      <v-btn
        title
        color="success"
        v-if="showCustomButton"
        @click="customButton"
        class="mr-2 hidden-sm-and-down mr-2"
        elevation="0"
      >
        <v-icon color="white">mdi-save</v-icon>
        {{ customButtonText }}
      </v-btn>
    </v-system-bar>
  </div>
</template>
<script>
import ReportMenu from "@/components/custom/ReportMenu.vue";
import SelectList from "@/components/custom/SelectList.vue";
import Vue from "vue";
import { Option, Select } from "element-ui";
Vue.use(Select);
Vue.use(Option);
export default {
  created() {},
  watch: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    noNew: {
      type: Boolean,
      default: false,
    },
    permissions: null,
    reports: {
      type: Array,
      default: null,
    },
    showCustomButton: {
      type: Boolean,
      default: false,
    },
    customButtonText: {
      type: String,
      default: "",
    },
    showList: {
      type: Boolean,
      default: false,
    },
    listEditor: {
      type: String,
      default: "",
    },
    listPlaceholder: {
      type: String,
      default: "Select",
    },
  },
  newd() {},
  components: {
    ReportMenu,
    SelectList,
  },
  data() {
    return {
      search: "",
      list_value: null,
    };
  },
  methods: {
    newRecord() {
      this.$emit("newRecord");
    },
    viewRecord(row) {
      this.$emit("viewRecord", row);
    },
    sizeChanged(page) {
      this.$emit("sizeChanged", page);
    },
    fitlerData() {
      this.$emit("fitlerData", this.search, 1);
    },
    getData() {
      this.$emit("getData");
    },
    customButton() {
      this.$emit("customButton");
    },
    listChanged(item) {
      if (!this.empty(item)) {
        this.$emit("listChanged", item);
      } else {
        this.$emit("listChanged", null);
      }
    },
  },
  computed: {
    allowNew() {
      return true;
    },
    allowReports() {
      return !this.empty(this.permissions)
        ? this.permissions.filter((e) => e.permission == "View Reports")
            .length > 0
        : false;
    },
  },
  name: "IndexHeader",
};
</script>
